
import {computed, defineComponent, ref} from 'vue';
import Close from '@/components/icons/Close.vue';
import useShippingTypeOptions from '@/mixins/useShippingTypeOptions';
import partnerAPI from '@/service/partnerAPI';

export default defineComponent({
  name: 'SetInvoiceNumberModal',
  props: {
    isOpen: {
      type: Boolean,
    },
    order: {
      type: Object,
    },
  },
  components: {
    Close,
  },
  setup(props, { emit }) {
    const input = computed(() => {
      return {
        trackingCode: props.order?.trackingCode,
        shippingTypeId: props.order?.shippingTypeId,
      };
    });

    const { shippingTypeOptions } = useShippingTypeOptions(true);

    const setInvoiceNumber = async () => {
      await partnerAPI.partnerProduct.updateTrackingCodeUsingPUT({
        param: {
          orderId: props.order?.orderId,
          shippingTypeId: input.value.shippingTypeId,
          trackingCode: input.value.trackingCode,
        },
      });
      onClose();
    };

    const onClose = () => {
      console.log('emit');
      emit('onClose');
    };

    return {
      shippingTypeOptions,
      input,
      setInvoiceNumber,
      onClose,
    };
  },
});
