
import { computed, defineComponent, onMounted, ref } from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import BidListTable from './BidListTable.vue';
import QnAListTable from './QnAListTable.vue';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import useShippingTypeOptions from '@/mixins/useShippingTypeOptions';
import RefundReasonModal from '@/components/RefundReasonModal.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import SetInvoiceNumberModal from '@/components/SetInvoiceNumberModal.vue';
import salesAndOrderStatus from '@/constants/salesAndOrderStatus';
import router from '@/router';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';
import VisibleIcon from '@/components/icons/VisibleIcon.vue'
import InvisibleIcon from '@/components/icons/InvisibleIcon.vue'
import CaretRightIcon from '@/components/icons/CaretRightIcon.vue'

export default defineComponent({
  name: 'SaleDetail',
  components: {
    Container,
    BidListTable,
    QnAListTable,
    RefundReasonModal,
    SetInvoiceNumberModal,
    IconBase,
    CheckCircleIcon,
    RemoveIcon,
    VisibleIcon,
    InvisibleIcon,
    CaretRightIcon
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup(props) {
    const { salesStatus, orderStatus } = salesAndOrderStatus();
    const productDetail = ref<any>(null);
    const orderInfo = computed(() => {
      return productDetail.value.order || {};
    });
    const shippingInfo = ref(null);
    const productIdParam = ref<number>();
    const isShowRefundModal = ref(false);
    const route = useRoute();
    const isEditButtonDisabled = ref(true)
    const gotoSeller = (memberId) => {
      router.push({
        path: `/app/member/detail/${memberId}`
      })
    }
    const isInvisibled = computed(() =>
        productDetail.value.isInvisibled
    );
    const gotoRegisterStep = (step: number) => {
      if(productDetail.value.isDeleted) {
        alert('이미 삭제된 상품입니다.')
        return
      }
      if (productDetail.value) {
        router.push({
          path: '/app/product/register',
          query: {
            step,
            productId: productIdParam.value,
          },
        });
      }
    };
    const toggleRefundModal = () => {
      isShowRefundModal.value = !isShowRefundModal.value;
    };

    const paymentStatus = computed(() => {
      if (orderInfo.value.status === 'deposit_wait') {
        return '입금대기중';
      } else if (orderInfo.value.payDate) {
        return `결제완료(${formatDatePattern(orderInfo.value.payDate, 'yyyy-MM-dd HH:mm')})`
      } else {
        return '-';
      }
    });

    const { categoryDepth1Obj, categoryDepth2Obj } = useCategoryOptions();
    const { shippingTypeOptions } = useShippingTypeOptions(true);

    onMounted(async () => {
      productIdParam.value = route.params.productId
        ? parseInt(route.params.productId as string)
        : undefined;

      if (!productIdParam.value) {
        alert('There is no product id');
      } else {
        // 저장된 상품정보를 가져온다.
        await fetchProductInfo(productIdParam.value);
      }
    });

    const fetchProductInfo = async (productId) => {
      try {
        const { data } = await partnerAPI.adminSales.adminSalesInfo({
          productId,
        });

        console.log('data', data);
        productDetail.value = (data as any).data;

        if (productDetail.value.order) {
          await fetchShippingInfo(productDetail.value.order.orderNo);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchShippingInfo = async (orderNo) => {
      const { data } = await partnerAPI.adminSales.adminShippingTracking({
        ordernum: orderNo,
      });
      shippingInfo.value = (data as any).data;
    };

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    const invoice = () => {
      const isOpenInvoiceNumber = ref(false);

      const onChangeStatus = (e) => {
        const option = e.target.value;
        if (option) {
          if (option === 'shipped') {
            isOpenInvoiceNumber.value = true;
          } else {
            if (
              window.confirm(
                `[${orderStatus[orderInfo.value.status]?.label || '-'}] -> [${orderStatus[option].label}] 로 변경하시겠습니까?`
              )
            ) {
              changeStatus(option, productDetail.value.productId);
            }
          }
        }
        e.target.value = '';
      };

      const changeStatus = async (option, productId) => {
        try {
          console.log(option);
          const { data } =
            await partnerAPI.adminSales.adminOrderStatusUpdate({
              param: {
                productId,
                orderStatus: option,
              },
            });
          alert((data as any).message);
          await fetchProductInfo(productIdParam.value);
        } catch (e) {
          console.error(e.response.data);
          alert(getServerErrorMessage(e));
        }
      };

      const showShippingTracking = () => {
        if (!orderInfo.value.trackingCode) {
          alert('송장번호를 등록해주세요.');
          return;
        }
        window.open('', 'sh',
          'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
        );
        (document.getElementById('sh') as HTMLFormElement).submit();
      };

      return {
        isOpenInvoiceNumber,
        onChangeStatus,
        changeStatus,
        showShippingTracking,
      };
    };

    const handleClickDelete = async () => {
      if(productDetail.value.isDeleted) {
        alert('이미 삭제된 상품입니다.')
        return
      }
      if (confirm('상품 삭제시 복원이 불가능합니다.\n' +
          '정말로 삭제하시겠습니까?')) {
        try {
          const { data } = await partnerAPI.adminProduct.adminProductDelete({
            productId: parseInt(productDetail.value.productId),
          });
          alert((data as any).message);
          router.push('/app/sales');
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      }
    };

    const handleToggleVisible = async () => {
      if(productDetail.value.isDeleted) {
        alert('이미 삭제된 상품입니다.')
        return
      }
      // 상품보일경우
      if(!isInvisibled.value) {
        try {
          //숨기기
          await partnerAPI.adminProduct.adminProductHide({
            productId: parseInt(productDetail.value.productId)
          })
          alert('상품이 숨김처리 되었습니다.');
          await fetchProductInfo(productIdParam.value);
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      } else {
        try {
          //보이기
          await partnerAPI.adminProduct.adminProductShow({
            productId: parseInt(productDetail.value.productId)
          })
          alert('상품이 공개처리 되었습니다.');
          await fetchProductInfo(productIdParam.value);
        } catch (e) {
          alert(getServerErrorMessage(e));
        }
      }
    }

    return {
      paymentStatus,
      productIdParam,
      isShowRefundModal,
      productDetail,
      orderInfo,
      isEditButtonDisabled,
      shippingInfo,
      gotoSeller,
      fetchProductInfo,
      gotoRegisterStep,
      formatDate,
      formatDatePattern,
      toggleRefundModal,
      categoryDepth1Obj,
      categoryDepth2Obj,
      shippingTypeOptions,
      handleClickDelete,
      handleToggleVisible,
      convertNumWithComma,
      ...invoice(),
      salesStatus,
      orderStatus,
    };
  },
});
