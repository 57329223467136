<template>
  <div
    v-if="isOpen"
    class="
      fixed
      z-50
      inset-0
      bg-black bg-opacity-40
      flex
      items-center
      justify-center
    "
  >
    <div class="bg-white relative">
      <article class="invoice-number-modal">
        <header class="modal-header">
          <h1 class="modal__title">
            송장번호 입력
          </h1>
          <button class="modal__close-button" @click="onClose">
            <Close />
          </button>
        </header>
        <div class="modal-content">
          <div class="input-part-area flex items-center">
            <label class="input__label block shipping-label">배송회사</label>
            <select
              v-model="input.shippingTypeId"
              class="appearance-none input__select FormSelect"
            >
              <option v-for="item in shippingTypeOptions" :key="item.value" :value="item.value">
                {{ item.label }}
              </option>
            </select>
          </div>
          <div class="input-part-area flex items-center">
            <label for="invoiceNumber" class="input__label block shipping-label">송장번호</label>
            <input id="invoiceNumber" v-model="input.trackingCode" type="text" class="input__input shipping-input" placeholder="숫자만 입력해주세요.">
          </div>
        </div>
        <div class="justify-center confirm-modal-button-area">
          <button class="confirm-modal__button bg-black full"
            @click="setInvoiceNumber"
          >확인
          </button>
        </div>
      </article>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue';
import Close from '@/components/icons/Close.vue';
import useShippingTypeOptions from '@/mixins/useShippingTypeOptions';
import partnerAPI from '@/service/partnerAPI';

export default defineComponent({
  name: 'SetInvoiceNumberModal',
  props: {
    isOpen: {
      type: Boolean,
    },
    order: {
      type: Object,
    },
  },
  components: {
    Close,
  },
  setup(props, { emit }) {
    const input = computed(() => {
      return {
        trackingCode: props.order?.trackingCode,
        shippingTypeId: props.order?.shippingTypeId,
      };
    });

    const { shippingTypeOptions } = useShippingTypeOptions(true);

    const setInvoiceNumber = async () => {
      await partnerAPI.partnerProduct.updateTrackingCodeUsingPUT({
        param: {
          orderId: props.order?.orderId,
          shippingTypeId: input.value.shippingTypeId,
          trackingCode: input.value.trackingCode,
        },
      });
      onClose();
    };

    const onClose = () => {
      console.log('emit');
      emit('onClose');
    };

    return {
      shippingTypeOptions,
      input,
      setInvoiceNumber,
      onClose,
    };
  },
});
</script>

<style scoped lang="scss">
.invoice-number-modal {
  width: 500px;
  height: 355px;
  .modal {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 21px 0 30px;
      height: 83px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__title {
      font-size: 32px;
      font-weight: 800;
      line-height: 1.21;
    }

    &-content {
      padding: 0 24px;
    }
  }
}
.input {
  &-part-area {
    margin-top: 30px;
    .FormSelect {
      border: 0;
      border-bottom: 1px solid #202020;
      padding-left: 0;
      font-size: 16px;
      width: calc(100% - 120px);
    }

    + .input-part-area {
      margin-top: 16px;
    }

    & + .button-area {
      margin-top: 26px;
    }
  }
  &__input {
    border: 0;
    border-bottom: 1px solid #202020;
    height: 40px;
    color: #202020;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.2;

    &.full {
      width: 100%;
    }

    &.shipping-input {
      width: calc(100% - 120px);
    }
  }
  &__select {
    border-bottom: 1px solid #202020;
    height: 40px;
    width: calc(100% - 120px);
  }
  &__label {
    color: #202020;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    &.shipping-label {
      min-width: 120px;
    }
  }
}
.confirm-modal-button-area {
  position: absolute;
  bottom: 32px;
  left: 24px;
  width: calc(100% - 48px);
  display: flex;
  align-items: center;

  .confirm-modal__button {
    height: 60px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    width: 60%;
    color: #fff;

    &-cancel {
      color: #202020;
      width: 37%;
      background: #fff;
      border: 1px solid #202020;
      box-sizing: border-box;
      margin-right: 3%;
    }

    &.full {
      width: 100%;
    }
  }
}
</style>
